import { environment } from '../../../src/environments/environment';
import { APIMODULES } from '../models/api-modules.models';

export const API_MODULES: APIMODULES = {
  auth: '/Login',
  home: '/home',
  admin: '/UserManagement',
  common: '/Common',
  fileprocess: '/FileProcess',
  file:'/file',
  barspayment: '/BARSPayment',
  reconciliation:'/Reconciliation',
  questionnaire: '/Questionnaire', 
  imrenrolleddealer: '/IMREnrolledDealer'
};

const API_BASEURL = environment.apiBaseURL;

const AUTH_URL = {
  login: API_BASEURL + API_MODULES.auth + '/Token',
  resetPassword: API_BASEURL + API_MODULES.auth + '/ResetPassword',
  changePassword: API_BASEURL + API_MODULES.auth + '/ChangePassword',
  ssoLogon:  API_BASEURL + API_MODULES.auth + '/SSOLogon'
};

const HOME_URL = {
  home: API_BASEURL,
};

const COMMON_URL = {
  programList: API_BASEURL + API_MODULES.common + '/GetProgramList',
  fileTypes: API_BASEURL + API_MODULES.common + '/GetFileTypeList',
  vendorList: API_BASEURL + API_MODULES.common + '/GetVendorList'
};

const ADMIN_URL = {
  user_lookup: API_BASEURL + API_MODULES.admin + '/GetUserList',
  saveUser: API_BASEURL + API_MODULES.admin + '/SaveUser',
  changeStatus: API_BASEURL + API_MODULES.admin + '/DeactivateUser',
};

const FILE_URL = {
  file_history : API_BASEURL + API_MODULES.fileprocess + '/FileHistory',
  file_saveprocess: API_BASEURL + API_MODULES.fileprocess + '/SaveFileProcess',
  dowloadfile:API_BASEURL + API_MODULES.file + '/DownloadFile',
  downloadstaticfile:API_BASEURL + API_MODULES.file + '/DownloadStaticFile'
}

const REPORTS_URL = {
  save_report: API_BASEURL + API_MODULES.barspayment + '/SaveReport',
  get_report: API_BASEURL + API_MODULES.barspayment + '/GetReport',
  get_recon_report: API_BASEURL + API_MODULES.reconciliation + '/GetReport',
  save_recon_report: API_BASEURL + API_MODULES.reconciliation  + '/SaveReport',
  save_imrdealer_report: API_BASEURL + API_MODULES.imrenrolleddealer + '/SaveReport',
  get_coop_imr_report: API_BASEURL + API_MODULES.imrenrolleddealer + '/GetReport',
}

const QUESTIONNAIRE_URL = {
  getProgram: API_BASEURL + API_MODULES.questionnaire + '/GetProgram',
  saveQuestionnaireForm:API_BASEURL + API_MODULES.questionnaire + '/SaveQuestionnaire',
  getQuestionnaireForm: API_BASEURL + API_MODULES.questionnaire + '/GetQuestionnaire',
  postSubmissionHistoryList: API_BASEURL + API_MODULES.questionnaire + '/QuestionnaireHistoryList',
  updateSubmissionQuestionnaireStatus: API_BASEURL + API_MODULES.questionnaire + '/UpdateQuestionnaireStatus',
  addressvalidate: API_BASEURL + API_MODULES.questionnaire + '/AddressValidate',


}



export const API_CONFIG = {
  AUTH_URL,
  HOME_URL,
  ADMIN_URL,
  COMMON_URL,
  FILE_URL,
  REPORTS_URL,
  QUESTIONNAIRE_URL
 
};
